import React, { useEffect, useState } from 'react';
import './App.css';
import img2 from "../assets/img2.png";

export default function AddGiftDetails() {
  const [giftMessage, setGiftMessage] = useState('');
  const [giftFrom, setGiftFrom] = useState('');
  const [email, setEmail] = useState('');
  const [address1, setAddress1] = useState('');
  const [city, setCity] = useState('');
  const [province, setProvince] = useState('');
  const [isLightboxOpen, setIsLightboxOpen] = useState(false);
  const [mediaUrl, setMediaUrl] = useState('')
  const [imageUrl, setImageUrl] = useState('')
  const [cardUrl, setCardUrl] = useState('')
  // const [colorCode, setColorCode] = useState('')
  // State for controlling lightbox

  useEffect(async () => {
    const params = new URLSearchParams(window.location.search);

    console.log(params.get('token'), "params tokentoken")
    const token = params.get("token");

    if (token) {
      console.log("Processing API call...");
      const details = await fetchDetails(token);
      if (!details) {
        alert("Order not created yet.");
        return;
      }
      console.log(details, "details")
      const mediaURL = details?.audio || details?.video;
      console.log(mediaURL, "------======------mediaUrl")
      setGiftMessage(details.giftMessage || '');
      setGiftFrom(details.giftFrom || '');
      setCardUrl(details.card || '')
      setMediaUrl(mediaURL || '');
      setProvince(details.shippingAddress.country || '');
      setAddress1(details.shippingAddress.address1 || '');
      setCity(details.shippingAddress.city || '');

      setImageUrl(details.image || '');
      setEmail(details.email || '');
    } else {
      console.log(params.get('giftMessage'), "params");

      setGiftMessage(params.get('giftMessage') || '');
      setGiftFrom(params.get('giftFrom') || '');
      setEmail(params.get('email') || '');
      setAddress1(params.get('address1') || '');
      setCity(params.get('city') || '');
      setProvince(params.get('country') || '');
      setImageUrl(params.get('image') || '');
      setMediaUrl(params.get('media') || '');
      setCardUrl(params.get('card') || '')
      // setColorCode(params.get('bg') || '')
    }


  }, []);


  const fetchDetails = async (token) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/users/api/getDetails`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ token }),
      });

      if (!response.ok) {
        if (response.status === 404) {
          throw new Error("Order not created yet.");
        } else {
          throw new Error("Failed to fetch order details.");
        }
      }

      return await response.json();
    } catch (error) {
      console.error("Error fetching details:", error.message);
      return null;
    }
  };

  const openLightbox = () => {
    if (mediaUrl) {  // Only open lightbox if mediaUrl exists
      setIsLightboxOpen(true);
    }
  };
  const closeLightbox = () => {
    setIsLightboxOpen(false);
  };
  const isVideo = mediaUrl.includes('video/');

  return (
    <div className='main_gift_shop'>
      <div className="addgift-main1" style={{ backgroundColor: 'rgba(30, 56, 241, 0.54)', width: '100%', position: 'relative' }}>
        <div className="teaser-preview1" id="teaserPreview">
          <p id="teaserMessage" style={{ color: 'rgb(255, 255, 255)' }}>
            A gift from <span id="senderName">{giftFrom || 'Your Name'}</span> is on its way!
          </p>
          <div className="teaser-card1">
            <div className='content_gift'>
              <p>To: <span>{email.split('@')[0]}</span></p>
              <p>Address: {address1} {city}</p>
              <p>{province}</p>
            </div>
            <img src={cardUrl} alt="Teaser look" id="teaserImage" className="img-fluid" />
          </div>
        </div>
      </div>

      <label htmlFor="teaserOptions" className="form-label teaser-lable">{giftMessage}</label>
      <div id="teaserOptions" className="d-flex flex-wrap">
        {imageUrl && imageUrl !== 'null' && (
          <div
            className="teaser-option"
            data-color="rgba(30, 56, 241, 0.54)"
            data-image={imageUrl}
            data-textcolor="#fff"
          >
            <div className="color-div" style={{ backgroundColor: '#333' }}>
              <img
                src={imageUrl}
                alt="Product"
                style={{
                  width: '100%',
                  height: '100%',
                  objectFit: 'cover',
                }}
              />
            </div>
            <p>Product image</p>
          </div>
        )}

        {/* <div className="teaser-option" data-color="rgba(30, 56, 241, 0.54)" data-image={imageUrl}data-textcolor="#fff">
          <div className="color-div" style={{ backgroundColor: '#333' }}>
            {imageUrl && (
              <img
                src={imageUrl}
                alt="Product"
                style={{
                  width: '100%',
                  height: '100%',
                  objectFit: 'cover' 
                }}
              />
            )}
          </div>
          <p>Product image</p>
        </div> */}

        {/* Message teaser with audio/video */}
        {mediaUrl && mediaUrl !== 'null' && (
          <div
            className="teaser-option"
            data-color="#ADD8E6"
            data-image={mediaUrl}
            data-textcolor="#000"
          >
            <div
              className="color-div"
              style={{ backgroundColor: '#333' }}
              onClick={openLightbox}
            >
              <svg
                width="64px"
                height="64px"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className="play_icon"
              >
                <g id="SVGRepo_bgCarrier" strokeWidth="0" />
                <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round" />
                <g id="SVGRepo_iconCarrier">
                  <path
                    d="M21.4086 9.35258C23.5305 10.5065 23.5305 13.4935 21.4086 14.6474L8.59662 21.6145C6.53435 22.736 4 21.2763 4 18.9671L4 5.0329C4 2.72368 6.53435 1.26402 8.59661 2.38548L21.4086 9.35258Z"
                    fill="#fff"
                  />
                </g>
              </svg>
            </div>
            <p>Audio/Video</p>
          </div>
        )}

        {/* <div
          className={`teaser-option ${!mediaUrl || mediaUrl === 'null' ? 'unclickable' : ''}`}
          data-color="#ADD8E6" data-image={mediaUrl} data-textcolor="#000">
          <div className="color-div" style={{ backgroundColor: '#333' }}
            onClick={mediaUrl && mediaUrl !== 'null' ? openLightbox : null}>

            <svg width="64px" height="64px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" className='play_icon'>
              <g id="SVGRepo_bgCarrier" stroke-width="0" />
              <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round" />
              <g id="SVGRepo_iconCarrier">
                <path d="M21.4086 9.35258C23.5305 10.5065 23.5305 13.4935 21.4086 14.6474L8.59662 21.6145C6.53435 22.736 4 21.2763 4 18.9671L4 5.0329C4 2.72368 6.53435 1.26402 8.59661 2.38548L21.4086 9.35258Z" fill="#fff" /> </g>
            </svg>
          </div>
          <p>Audio/Video</p>
        </div> */}
      </div>

      {/* Lightbox Modal for Video */}
      {isLightboxOpen && (
        <div className="lightbox-overlay" onClick={mediaUrl ? closeLightbox : null}
          style={{
            pointerEvents: mediaUrl ? 'auto' : 'none', // Disable clicks if mediaUrl is null
          }}
        >
          <div className={`lightbox-content ${!isVideo ? 'audio_popup' : ''}`}
            onClick={(e) => e.stopPropagation()}>
            <button onClick={closeLightbox} className="close-lightbox-btn">&times;</button>

            {mediaUrl && (
              <>
                {isVideo ? (
                  <video controls className='video_fram'>
                    <source src={mediaUrl} type="video/mp4" />
                    Your browser does not support the video tag.
                  </video>
                ) : (
                  <audio controls className='audio_fram'>
                    <source src={mediaUrl} type="audio/mpeg" />
                    Your browser does not support the audio tag.
                  </audio>
                )}
              </>
            )}
          </div>
        </div>
      )}
    </div>
  );
}
